import React from "react"
import Layout from "../../components/layout/layout"
import Status from "../../documents/Statuts.pdf"
import Inscription from "../../documents/inscription.pdf"
import reglement_interne from "../../documents/reglement_interne.pdf"
import SmallHero from "../../components/layout/smallHero"
import { Helmet } from "react-helmet"

import "../mystyles.scss"

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Documents utiles</title>
      </Helmet>
      <SmallHero title="Documents utiles" color="is-warning" />
      <section className="section">
        <div className="container content">
          <ul>
            <li>
              Les statuts :{" "}
              <a href={Status} download>
                télécharger
              </a>
            </li>
            <li>
              Bulletin d'inscription 2022-2023 :{" "}
              <a href={Inscription} download>
                télécharger
              </a>
            </li>
            <li>
              Règlement intérieur :{" "}
              <a href={reglement_interne} download>
                télécharger
              </a>
            </li>
          </ul>
        </div>
      </section>
    </Layout>
  )
}
